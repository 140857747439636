import React, { useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useTranslation, Trans } from '@3nvi/gatsby-theme-intl';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./get_offer.scss";


const GetOfferSection = () => {
  const { t } = useTranslation();

  const [name, setName] = useState('')
  const handleNameChange = event => {
    setName(event.target.value)
  };

  const [email, setEmail] = useState('')
  const handleEmailChange = event => {
    setEmail(event.target.value)
  };

  const [country, setCountry] = useState('')
  const handleCountryChange = event => {
    setCountry(event.target.value)
  };

  const [team, setTeam] = useState('')
  const handleTeamChange = event => {
    setTeam(event.target.value)
  };

  const [message, setMessage] = useState('')
  const handleMessageChange = event => {
    setMessage(event.target.value)
  };

  const resetForm = () => {
    setName('')
    setEmail('')
    setCountry('')
    setTeam('')
    setMessage('')
  }

  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
      resetForm()
    }, 3000)

    const data = {
      first_name: name,
      last_name: '.',
      email,
      message: 'Country: ' + country +
        '\r\n Team:' + team +
        '\r\n Message: ' + message
    }

    axios({
      method: "post",
      url: "https://skillcourts-api.skillgam.es/api/contact",
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then( (resp) => {
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <section className="get-offer-section">
      <div className="row">
        <div className="col-md-12">
          <h4 className="section-heading">
            <Trans i18nKey="Contact.title"/>
          </h4>
          <p className="section-subheading">
            { t("Contact.subtitle") }
          </p>
        </div>
        <div className="col-md-6 form-col">
          {!submitting &&
            <form onSubmit={handleSubmit}>
              <div className="form-row mb-2">
                <div className="form-group col-md-6 text-left">
                  <label htmlFor="inputName">{ t("Form.name") }</label>
                  <input type="text" name="name" className="form-control" id="inputName"
                         onChange={handleNameChange}
                         value={name} required/>
                </div>
                <div className="form-group col-md-6 text-left">
                  <label htmlFor="inputEmail4">Email</label>
                  <input type="email" name="email" className="form-control" id="inputEmail4"
                         onChange={handleEmailChange}
                         value={email} required/>
                </div>
              </div>
              <div className="form-row mb-2">
                <div className="form-group col-md-6 text-left">
                  <label htmlFor="inputCountry">{ t("Form.country") }</label>
                  <input type="text" name="country" className="form-control" id="inputCountry"
                         onChange={handleCountryChange}
                         value={country} required/>
                </div>
                <div className="form-group col-md-6 text-left">
                  <label htmlFor="inputTeam">Team</label>
                  <input type="text" name="team" className="form-control" id="inputTeam"
                         onChange={handleTeamChange}
                         value={team}/>
                </div>
              </div>
              <div className="form-row mb-2">
                <div className="form-group col-md-12 text-left">
                  <label htmlFor="inputAdditional">{ t("Form.additional") }</label>
                  <textarea className="form-control" name="additional" id="inputAdditional" rows="3"
                            onChange={handleMessageChange}
                            value={message} required/>
                </div>
              </div>
              <Button
                className="main-action-btn"
                style={{ fontWeight: 500, marginTop: 0}}
                type='submit'>{ t("Form.get-an-offer") }
              </Button>
            </form>
          }
          {submitting &&
            <div className="mt-5 fadeIn">
              <FontAwesomeIcon icon={ faCheckCircle } size="6x" className="form-check-icon"/>
            </div>
          }
        </div>
      </div>
    </section>
)
}

export default GetOfferSection
