import React from "react";
import "./benefitsHeader.scss";
import VisibilitySensor from "../../../VisibilitySensor";
import { Spring } from "react-spring/renderprops";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { useTranslation } from '@3nvi/gatsby-theme-intl';


const BenefitsHeader = () => {
  const data = useStaticQuery(getWhatImages)
  const { t } = useTranslation();

  return (
    <div className="banner-row row">
      <div className="col-md-12 col-lg-8 d-flex flex-column justify-content-center" style={{zIndex: 2}}>
        <VisibilitySensor once>
          {({isVisible}) => (
            <Spring delay={300}
                    to={{
                      opacity: isVisible ? 1 : 0,
                      transform: isVisible
                        ? "translateY(0)"
                        : "translateY(200px)"
                    }}>
              {({opacity, transform}) => (
                <h4 style={{opacity, transform}} className={"section-heading mt-0 text-left"}>
                  { t("BenefitsSection.heading") }
                </h4>
              )}
            </Spring>
          )}
        </VisibilitySensor>
      </div>
      <div className="tinted">
        <Image className="banner-img"
               fluid={data.benefitsGamification.childImageSharp.fluid}
               alt=""/>
      </div>
    </div>
  )
}

export default BenefitsHeader

export const getWhatImages = graphql`
    query {
        benefitsGamification: file(relativePath: {eq: "runningtrack1.png"}) {
            childImageSharp {
                fluid(maxHeight: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
