import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from '@3nvi/gatsby-theme-intl';

import Image from "gatsby-image";
import "./smartCities.scss";


const SmartCities = () => {
    const data = useStaticQuery(getWhatImages);
    const { t } = useTranslation();

    return (
        <div className="smart-cities benefits-item row">
            <div className="col-md-12">
                <div className="benefits-numbering">
                    <p>3</p>
                </div>
                <p className="section-heading-description">
                    { t("BenefitsSection.smart-cities-header") }
                </p>
                <h4 className="section-heading">
                    { t("BenefitsSection.smart-cities-name") }
                </h4>
                <p className="section-subheading">
                    { t("BenefitsSection.smart-cities-desc") }
                </p>
            </div>
            <div className="col-md-8">
                <Image className="benefits-item-img mt-5"
                    style={{zIndex: 3}}
                    fluid={data.benefitsSmartCity.childImageSharp.fluid}
                    alt=""/>
            </div>
        </div>
    )
}

export default SmartCities

export const getWhatImages = graphql`
    query {
        benefitsSmartCity: file(relativePath: {eq: "benefits-smart-city_new.png"}) {
            childImageSharp {
                fluid(maxHeight: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
