import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "../../VisibilitySensor";
import Image from "gatsby-image";
import { useTranslation, Trans } from '@3nvi/gatsby-theme-intl';
import BenefitsHeader from "./benefitsHeader";
import Gamification from "./gamification";
import DataAnalytics from "./dataAnalytics";
import SmartCities from "./smartCities";
import Competitiveness from "./competitiveness";
import "./why.scss";


const WhySection = () => {
    const data = useStaticQuery(getWhatImages)
    const { t } = useTranslation();

    return (
        <section className="why-section">
            <section className="subsection intro">
                <div className="row" style={{alignItems: 'center'}}>
                    <div className="col-sm-12 col-lg-6" style={{height: 'fit-content'}}>
                        <VisibilitySensor partialVisibility once>
                            {({isVisible}) => (
                                <Spring delay={150}
                                        to={{
                                            opacity: isVisible ? 1 : 0,
                                            transform: isVisible
                                                ? "translateX(0)"
                                                : "translateX(-300px)"
                                        }}>
                                    {({opacity, transform}) => (
                                        <div className="header">
                                            <h4 style={{opacity, transform}} className={"section-heading"}>
                                                <Trans i18nKey="WhySection.heading"/>
                                            </h4>
                                        </div>
                                    )}
                                </Spring>
                            )}
                        </VisibilitySensor>
                        <VisibilitySensor partialVisibility once>
                            {({isVisible}) => (
                                <Spring delay={300}
                                        to={{
                                            opacity: isVisible ? 1 : 0,
                                            transform: isVisible
                                                ? "translateX(0)"
                                                : "translateX(-300px)"
                                        }}>
                                    {({opacity, transform}) => (
                                        <div style={{opacity, transform}} >
                                            <p className="section-subheading d-none d-lg-block">
                                                { t("WhySection.subheading-1") }
                                            </p>
                                            <p className="section-subheading d-none d-lg-block">
                                                { t("WhySection.subheading-2") }
                                            </p>
                                        </div>
                                    )}
                                </Spring>
                            )}
                        </VisibilitySensor>
                    </div>
                    <div className="col-sm-12 col-lg-6" style={{height: 'fit-content'}}>
                        <Image className="multisports-img"
                            fluid={data.skillcourtParkVertical.childImageSharp.fluid} alt=""/>
                    </div>
                    <div className="d-flex flex-column align-items-center col-sm-12 col-lg-6 d-lg-none">
                        <p className="section-subheading">
                            { t("WhySection.subheading-1") }
                        </p>
                        <p className="section-subheading">
                            { t("WhySection.subheading-2") }
                        </p>
                    </div>
                </div>
            </section>
            <section>
                <BenefitsHeader/>
                <Gamification/>
                <DataAnalytics/>
                <SmartCities/>
                <Competitiveness/>
            </section>
        </section>
    )
}

export default WhySection

export const getWhatImages = graphql`
    query {
        skillcourtPark: file(relativePath: {eq: "skillcourt-park.png"}) {
            childImageSharp {
                fluid(maxHeight: 150) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        skillcourtParkVertical: file(relativePath: {eq: "multisports.png"}) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
