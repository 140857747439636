import React from "react";
import "./welcome.scss";
import SmartLightVideo from "../../../videos/skillcourt_promo_video.mp4";

const WelcomeSection = () => {
  return (
    <section className="welcome-section justify-content-center position-relative">
      <video autoPlay loop controls muted className="skillcourt-video"
             onClick={e => e.target.play()}>
        <source src={SmartLightVideo} type="video/mp4"/>
      </video>
    </section>
  )
}

export default WelcomeSection
