import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from '@3nvi/gatsby-theme-intl';

import Circle from "../../../videos/Circle.mp4";
import Runningtrack from "../../../videos/Runningtrack.mp4";
import Square from "../../../videos/Square.mp4";
import "./what.scss";


const WhatSection = () => {
  const { t } = useTranslation();

  return (
    <section className="what-section">
      <div className="configurations">
        <div className="row">
          <div className="col-lg-12">
            <h3>{ t("Configuration.heading") }</h3>
          </div>
        </div>
        <div className="row mt-5 pb-5">
          <div className="col-lg-5">
            <video autoPlay loop muted className="configuration-video"
                   onClick={e => e.target.play()}>
              <source src={ Runningtrack } type="video/mp4"/>
            </video>
          </div>
          <div className="col-lg-7 configuration-item text-left">
            <div className="row">
              <div className="col-lg-12">
                <h4>{ t("Configuration.runningtrack-name") }</h4>
                <p className="description">
                  { t("Configuration.runningtrack-explanation") }
                </p>
                <p className="mb-1">
                  <span className="font-weight-bold">{ t("Configuration.suits") }: </span>{ t("Configuration.runningtrack-suits") }
                </p>
                <p>
                  <span className="font-weight-bold">{ t("Configuration.applications") }: </span>{ t("Configuration.runningtrack-applications") }
                </p>
              </div>
              <div className="d-flex mt-3">
                <div className="col-lg-12 details">
                  <h5>{ t("Configuration.specifications") }</h5>
                  <div className="mt-2">
                    <p>
                      { t("Configuration.runningtrack-specs1") }
                    </p>
                    <p>
                      { t("Configuration.runningtrack-specs2") }
                    </p>
                    <p>
                      { t("Configuration.runningtrack-specs3") }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 pb-5">
          <div className="col-lg-5">
            <video autoPlay loop muted className="configuration-video"
                   onClick={e => e.target.play()}>
              <source src={Square} type="video/mp4"/>
            </video>
          </div>
          <div className="col-lg-7 configuration-item text-left">
            <div className="row">
              <div className="col-lg-12">
                <h4>{ t("Configuration.square-name") }</h4>
                <p className="description">
                  { t("Configuration.square-explanation") }
                </p>
                <p className="mb-1">
                  <span className="font-weight-bold">{ t("Configuration.suits") }: </span>{ t("Configuration.square-suits") }
                </p>
                <p>
                  <span className="font-weight-bold">{ t("Configuration.applications") }: </span>{ t("Configuration.square-applications") }
                </p>
              </div>
              <div className="d-flex mt-3">
                <div className="col-lg-12 details">
                  <h5>{ t("Configuration.specifications") }</h5>
                  <div className="mt-2">
                    <p>
                      { t("Configuration.square-specs1") }
                    </p>
                    <p>
                      { t("Configuration.square-specs2") }
                    </p>
                    <p>
                      { t("Configuration.square-specs3") }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 pb-5">
          <div className="col-lg-5">
            <video autoPlay loop muted className="configuration-video"
                   onClick={e => e.target.play()}>
              <source src={Circle} type="video/mp4"/>
            </video>
          </div>
          <div className="col-lg-7 configuration-item text-left">
            <div className="row">
              <div className="col-lg-12">
                <h4>{ t("Configuration.circle-name") }</h4>
                <p className="description">
                  { t("Configuration.circle-explanation") }
                </p>
                <p className="mb-1">
                  <span className="font-weight-bold">{ t("Configuration.suits") }: </span>{ t("Configuration.circle-suits") }
                </p>
                <p>
                  <span className="font-weight-bold">{ t("Configuration.applications") }: </span>{ t("Configuration.circle-applications") }
                </p>
              </div>
              <div className="d-flex mt-3">
                <div className="col-lg-12 details">
                  <h5>{ t("Configuration.specifications") }</h5>
                  <div className="mt-2">
                    <p>
                      { t("Configuration.circle-specs1") }
                    </p>
                    <p>
                      { t("Configuration.circle-specs2") }
                    </p>
                    <p>
                      { t("Configuration.circle-specs3") }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatSection

export const getWhatImages = graphql`
    query {
        roundConfiguration: file(relativePath: {eq: "round-configuration.jpg"}) {
            childImageSharp {
                fluid(maxHeight: 150) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        runningTrackConfiguration: file(relativePath: {eq: "how-the-zone-sm.png"}) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        squareConfiguration: file(relativePath: {eq: "skillcourts-vertical.png"}) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
