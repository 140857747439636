import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from '@3nvi/gatsby-theme-intl';

import Image from "gatsby-image";
import "./gamification.scss";


const Gamification = () => {
    const data = useStaticQuery(getWhatImages);
    const { t } = useTranslation();

    return (
        <div className="gamification benefits-item row">
            <div className="col-md-6 gamification-image-col">
                <Image className=" benefits-item-img"
                       fluid={data.benefitsGamification.childImageSharp.fluid}
                       alt=""/>
            </div>
            <div className="col-md-6 gamification-text-col">
                <div className="benefits-numbering">
                    <p>1</p>
                </div>
                <p className="section-heading-description">
                    { t("BenefitsSection.gamification-header") }
                </p>
                <h4 className="section-heading">
                    { t("BenefitsSection.gamification-name") }
                </h4>
                <p className="section-subheading">
                    { t("BenefitsSection.gamification-desc") }
                </p>
            </div>
        </div>
    )
}

export default Gamification

export const getWhatImages = graphql`
    query {
        benefitsGamification: file(relativePath: {eq: "benefits_gamification_new.png"}) {
            childImageSharp {
                fluid(maxHeight: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
