import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import "./how.scss";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "../../VisibilitySensor";
import { useTranslation, Trans } from '@3nvi/gatsby-theme-intl';

const HowSection = () => {
  const data = useStaticQuery(getWhyImages);
  const { t } = useTranslation();

  return (
    <section className="how-section">
      <section className="subsection intro position-relative">
        <div className="banner-row row">
          <div className="col-md-12 col-lg-8 m-auto d-flex flex-column justify-content-center" style={{zIndex: 3}}>
            <VisibilitySensor partialVisibility once>
              {({isVisible}) => (
                <Spring delay={350}
                        to={{
                          opacity: isVisible ? 1 : 0,
                          transform: isVisible
                            ? "translateY(0)"
                            : "translateY(200px)"
                        }}>
                  {({opacity, transform}) => (

                    <h4 style={{opacity, transform}} className={"section-heading"}>
                      <Trans i18nKey="HowSection.heading"/>
                    </h4>
                  )}
                </Spring>
              )}
            </VisibilitySensor>
          </div>
          <div className="tinted">
            <Image className="banner-img"
                   fluid={data.projectOfAllSizes.childImageSharp.fluid} alt=""/>
          </div>
        </div>
      </section>
      <section className="subsection the-zone">
        <div className="row">
          <VisibilitySensor partialVisibility once>
            {({isVisible}) => (
              <Spring delay={500}
                      to={{
                        opacity: isVisible ? 1 : 0
                      }}>
                {({opacity}) => (
                  <div style={{opacity}} className="col-md-6 the-zone-image-col">
                    <Image className="the-zone-img" fluid={data.theZone.childImageSharp.fluid}
                           alt=""/>
                  </div>
                )}
              </Spring>
            )}
          </VisibilitySensor>
          <div className="col-md-6 the-zone-text-col">
            <VisibilitySensor partialVisibility once>
              {({isVisible}) => (
                <Spring delay={150}
                        to={{
                          opacity: isVisible ? 1 : 0,
                          transform: isVisible
                            ? "translateX(0)"
                            : "translateX(300px)"
                        }}>
                  {({opacity, transform}) => (
                    <h4 style={{opacity, transform, fontWeight: "600"}}
                        className={"section-heading mb-5"}>
                      <Trans i18nKey="HowSection.the-zone-header"/>
                    </h4>
                  )}
                </Spring>
              )}
            </VisibilitySensor>
            <VisibilitySensor partialVisibility once>
              {({isVisible}) => (
                <Spring delay={300}
                        to={{
                          opacity: isVisible ? 1 : 0,
                          transform: isVisible
                            ? "translateX(0)"
                            : "translateX(300px)"
                        }}>
                  {({opacity, transform}) => (
                    <p style={{opacity, transform}} className={"section-subheading"}>
                      { t("HowSection.the-zone-desc") }
                    </p>

                  )}
                </Spring>
              )}
            </VisibilitySensor>
          </div>
        </div>
      </section>

      <section className="subsection the-station">
        <VisibilitySensor once>
          {({isVisible}) => (
            <div className="row">
              <Spring config={{duration: 400}}
                      delay={100}
                      to={{
                        opacity: isVisible ? 1 : 0,
                        transform: isVisible
                          ? "translateX(0)"
                          : "translateX(100px)"
                      }}>
                {({opacity, transform}) => (
                  <div style={{opacity, transform, animationDuration: "10s"}}
                       className="col-md-6 the-station-text-col">
                    <div>
                      <h4 className={"section-heading mb-5"} style={{fontWeight: "600"}}>
                        <Trans i18nKey="HowSection.the-pole-header"/>
                      </h4>
                      <p className={"section-subheading"}>
                        { t("HowSection.the-pole-desc") }
                      </p>
                    </div>
                  </div>
                )}
              </Spring>
              <Spring config={{duration: 400}}
                      delay={100}
                      to={{
                        transform: isVisible
                          ? "translateX(0)"
                          : "translateX(-50%)"
                      }}>
                {({transform}) => (
                  <div style={{transform}} className="col-md-6 the-station-col">
                    <Image className="the-station-img" fluid={data.thePole.childImageSharp.fluid}
                           alt=""/>
                  </div>
                )}
              </Spring>
            </div>
          )}
        </VisibilitySensor>
      </section>
    </section>
  )
}

export default HowSection

export const getWhyImages = graphql`
    query {
        projectOfAllSizes: file(relativePath: {eq: "20210602 Smartgoals_009.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        skillcourtField: file(relativePath: { eq: "skillcourt-field.png" }) {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        theZone: file(relativePath: { eq: "20210602 Smartgoals_100.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 750) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        theStation: file(relativePath: { eq: "pole.png" }) {
            childImageSharp {
                fluid(maxWidth: 1500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        thePole: file(relativePath: { eq: "smart_pole.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
