import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from '@3nvi/gatsby-theme-intl';

import Image from "gatsby-image";
import "./dataAnalytics.scss";


const DataAnalytics = () => {
    const data = useStaticQuery(getWhatImages);
    const { t } = useTranslation();

    return (
        <div className="data-analytics benefits-item row">
            <div className="col-md-6 order-last order-md-first data-analytics-text-col">
                <div className="benefits-numbering">
                    <p>2</p>
                </div>
                <p className="section-heading-description">
                    { t("BenefitsSection.data-analytics-header") }
                </p>
                <h4 className="section-heading">
                    { t("BenefitsSection.data-analytics-name") }
                </h4>
                <p className="section-subheading">
                    { t("BenefitsSection.data-analytics-desc") }
                </p>
            </div>
            <div className="col-md-6 order-first order-md-last data-analytics-image-col">
                <Image className="benefits-item-img"
                       fluid={data.benefitsDataAnalytics.childImageSharp.fluid}
                       alt=""/>
            </div>
        </div>
    )
}

export default DataAnalytics

export const getWhatImages = graphql`
    query {
        benefitsDataAnalytics: file(relativePath: {eq: "benefits-data-analytics_new.png"}) {
            childImageSharp {
                fluid(maxHeight: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
