import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-scroll";
import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@3nvi/gatsby-theme-intl";
import * as footerStyles from "./footer.module.scss";

const Footer = () => {
  const data = useStaticQuery(graphql`
      query {
          skillcourts: file(relativePath: {eq: "SkillCourts-logo.png"}) {
              childImageSharp {
                  fixed(width: 250, quality: 100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          whsports: file(relativePath: {eq: "wh_sports.jpeg"}) {
              childImageSharp {
                  fixed(width: 100, quality: 100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `)

  const { t } = useTranslation();

  return (
    <footer className="pt-5 pb-5">
      <div className='container-fluid'>
        <div className={`row ${footerStyles.footerNav}`}>
          <div className={`${footerStyles.footerLogos} col-md-12 col-lg-4 d-flex flex-column justify-content-center`}>
            <Img
              className="gatsby-image"
              imgStyle={{ objectFit: 'contain' }}
              fixed={data.skillcourts.childImageSharp.fixed}
            />
            <Img
              className="gatsby-image wh-sports-image"
              imgStyle={{ objectFit: 'contain' }}
              fixed={data.whsports.childImageSharp.fixed}
            />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-2">
            <Nav activeKey="/home" className={footerStyles.navBlock}>
              <h4>Urban SkillCourt</h4>
              <Nav.Item>
                <Link activeClass="active" className="nav-link pr-lg-4" to="section-2" spy={true} smooth={true} duration={500}>
                  {t("Menu.about")}
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://smartgoals.nl/contact/"
                  target="_blank" rel="noopener noreferrer">
                  {t("Menu.contact-us")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-2">
            <Nav activeKey="/home" className={footerStyles.navBlock}>
              <h4>Help</h4>
              <Nav.Item>
                <Nav.Link
                  href='https://gdpr.smartgoals.nl/skillcourts/1.0/18112022_Algemene_Voorwaarden_SmartGoals.pdf'
                  target="_blank" rel="noopener noreferrer">
                  {t("Menu.general-terms")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href='https://gdpr.smartgoals.nl/skillcourts/1.0/07072021_PrivacyVerklaring_SmartGoals_Urban_SkillCourt.pdf'
                  target="_blank" rel="noopener noreferrer">
                  {t("Menu.privacy-policy")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <Nav className={`${footerStyles.navBlock} ${footerStyles.contactUs}`}>
              <h4>{t("Menu.contact-us")}</h4>
              <div className="d-flex flex-row align-items-center">
                <FontAwesomeIcon className={footerStyles.envelopIcon} icon={faEnvelope} size="2x" />
                info@smartgoals.nl
              </div>
              <div className="d-flex flex-row align-items-center">
                <FontAwesomeIcon className={footerStyles.envelopIcon} icon={faPhone} size="2x" />
                +31 252 823 071
              </div>
              <div className='d-flex flex-row align-items-center'>
                <a href="https://www.facebook.com/smartgoalssport/"
                  target="_blank" rel="noopener noreferrer"
                  className={`mr-3 ${footerStyles.socialNetworkLogo}`}>
                  <FontAwesomeIcon icon={faFacebookF} size="1x" />
                </a>
                <a href="https://www.instagram.com/smartgoalsfootball/"
                  target="_blank" rel="noopener noreferrer"
                  className={`mr-3 ${footerStyles.socialNetworkLogo}`}>
                  <FontAwesomeIcon className={footerStyles.socialNetworkLogo} icon={faInstagram} size="1x" />
                </a>
                <a href="https://twitter.com/smartgoalssport?lang=en"
                  target="_blank" rel="noopener noreferrer"
                  className={footerStyles.socialNetworkLogo}>
                  <FontAwesomeIcon icon={faTwitter} size="1x" />
                </a>
              </div>
            </Nav>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
